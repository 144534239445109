import Image from 'next/image';
import Link from 'next/link';

import { mediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const ProductParentCategory = ({ category }) => {
  const { id, logoUrl, name: categoryName, slug } = category;
  const parentCategoryURL = getPageURL({
    pageName: ROUTE_CONFIG.L0_CATEGORY.label,
    pathParams: {
      parentSlug: slug
    }
  });
  const kidsBirthdayEventURL = getPageURL({
    pageName: ROUTE_CONFIG.KIDS_BIRTHDAY_EVENTS.label
  });

  return (
    <Link
      key={id}
      href={`${
        slug !== 'kids-birthday-events'
          ? parentCategoryURL
          : kidsBirthdayEventURL
      }`}
    >
      <a className='mb-2 text-center cursor-pointer w-17.5 md:w-1/5'>
        <div className='mb-2 md:mb-2.5 w-13 h-13 md:w-20 md:h-20 rounded-20px mx-auto'>
          <div className='flex justify-center relative my-2 md:my-4 h-full z-index-negative'>
            <Image
              alt={categoryName}
              layout='fill'
              objectfit='initial'
              src={`${mediaStoreBaseURL}/${logoUrl}`}
            />
          </div>
        </div>
        <div className='text-xs md:text-base text-dim-gray font-medium font-Montserrat'>
          {categoryName}
        </div>
      </a>
    </Link>
  );
};

const ProductParentCategories = ({ categories }) => (
  <div className='flex flex-row overflow-x-scroll md:justify-center md:flex-col md:overflow-hidden no-scrollbar'>
    <div className='flex flex-nowrap space-x-3 justify-between items-start md:justify-center md:space-x-20 md:max-w-180 mx-auto'>
      {categories &&
        categories.map((category, index) => (
          <ProductParentCategory
            key={index}
            {...{
              category
            }}
          />
        ))}
    </div>
  </div>
);

export default ProductParentCategories;
