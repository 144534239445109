import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const RenderSortFilter = ({
  alt,
  iconUrl,
  label,
  onClickShowHideHandler,
  productFilterCount
}) => (
  <div
    className='w-1/2 flex justify-center items-center first:border-r first:border-gray h-12 bg-white border-t border-gray'
    onClick={onClickShowHideHandler}
  >
    <div className='relative h-5 w-5'>
      <div className='absolute -right-1 -top-1.5 z-20'>
        <div
          className={`${
            productFilterCount === 0 || !productFilterCount ? 'hidden' : ''
          } bg-brand-gradient h-4 w-4 rounded-full flex justify-center items-center`}
        >
          <Text
            {...{
              className: 'text-white font-Inter font-medium text-xxs',
              content: productFilterCount,
              HtmlTag: 'span'
            }}
          />
        </div>
      </div>
      <Image
        alt={alt}
        layout='fill'
        src={iconUrl}
      />
    </div>
    <Text
      {...{
        className:
          'text-base leading-5 font-medium font-Montserrat ms-2.5 text-nero',
        content: label,
        HtmlTag: 'span'
      }}
    />
  </div>
);

const ProductFilter = ({
  filterByShowHideHandler,
  filterCount,
  sortOrderShowHideHandler
}) => (
  <div className='flex'>
    <RenderSortFilter
      {...{
        alt: 'sort icon',
        iconUrl: `${staticMediaStoreBaseURL}/icons/bx-sort.svg`,
        label: 'Sort',
        productFilterCount: 0,
        onClickShowHideHandler: sortOrderShowHideHandler
      }}
    />
    <RenderSortFilter
      {...{
        alt: 'filter icon',
        iconUrl: `${staticMediaStoreBaseURL}/icons/bx-filter.svg`,
        label: 'Filter',
        productFilterCount: filterCount,
        onClickShowHideHandler: filterByShowHideHandler
      }}
    />
  </div>
);

export default ProductFilter;
