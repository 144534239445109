import { CloseIcon, Text } from '@/components/atomic/nuclei';

const SelectedFilterItem = ({ label, removeFilter, bemBlocks }) => (
  <label className='h-9 flex justify-between items-center border border-platinum cursor-pointer px-4 rounded-md min-w-30 hover:bg-ghost-white'>
    <Text
      {...{
        content: label,
        className: `text-dim-gray font-medium text-sm leading-17px capitalize me-3 select-none ${bemBlocks.option(
          'name'
        )}`,
        HtmlTag: 'span'
      }}
    />
    <CloseIcon {...{ onClick: removeFilter, width: 16, height: 16 }} />
  </label>
);

const SelectedFilter = ({ labelValue, filterId, bemBlocks, removeFilter }) => {
  let label = labelValue;
  if (filterId === 'sortPrice') {
    const [min, max] = labelValue.split(' - ');
    label = `AED ${min} - AED ${max}`;
  }

  return (
    <li>
      <SelectedFilterItem {...{ label, removeFilter, bemBlocks }} />
    </li>
  );
};

export default SelectedFilter;
