import Image from 'next/image';
import { useState } from 'react';
import {
  CheckboxFilter,
  NumericRefinementListFilter,
  RangeFilter,
  RangeSlider,
  SearchkitProvider,
  TermQuery
} from 'searchkit';

import { staticMediaStoreBaseURL } from '@/config/common';

const DesktopSideBarFilters = ({ priceRange, searchkit }) => {
  const [showDiscountFilter, setShowDiscountFilter] = useState(true);
  const discountsList = [
    {
      title: 'Below 10%',
      from: 0,
      to: 10
    },
    {
      title: '10% and Above',
      from: 10
    },
    {
      title: '20% and Above',
      from: 20
    },
    {
      title: '30% and Above',
      from: 30
    },
    {
      title: '40% and Above',
      from: 40
    }
  ];

  const renderPriceRange = (props) => (
    <div className='max-w-50 md:max-w-58 relative mb-7 ms-4.5'>
      <div className='text-xs text-nero block font-normal leading-4 pb-2 select-none -ml-4'>
        Selected price range
      </div>
      <div className='text-sm text-nero block font-semibold leading-4 pb-6 select-none -ml-4'>
        AED {props.minValue} - AED {props.maxValue}
      </div>
      <RangeSlider
        mod={'custom-slider'}
        {...props}
      />
    </div>
  );

  const renderCheckbox = (props) => (
    <ul id='instantprice'>
      <li className='flex justify-start items-center pb-11px'>
        <label>
          <div className='flex items-center'>
            <input
              type='checkbox'
              name='Instant Price Products'
              className='rounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
              onChange={props.toggleItem}
              defaultChecked={props.selectedItems.length}
            />
            <div className='text-nero font-normal text-sm leading-17px capitalize ml-3'>
              Instant Price Products Only
            </div>
          </div>
        </label>
      </li>
    </ul>
  );

  const renderDiscountItem = (props) =>
    props &&
    props.items.map((item) => (
      <label
        className={`flex items-center mb-3 cursor-pointer ${
          !showDiscountFilter ? 'hidden' : ''
        }`}
        key={item.key}
        onClick={(e) => {
          e.preventDefault();
          props.toggleItem();
          const setItem = props.selectedItems[0] !== item.key ? item.key : '';
          props.setItems(setItem);
        }}
      >
        <input
          type='radio'
          name={item.key}
          defaultChecked={props.selectedItems[0] === item.key}
          className='rounded box-border border-gray border-1 text-nero focus:border-nero focus:ring-nero w-4 h-4'
        />
        <span className='ms-3 text-nero font-normal font-Montserrat text-sm leading-17px select-none'>
          {item.key}
        </span>
      </label>
    ));

  const toggleDiscountHandler = () => {
    setShowDiscountFilter((prevState) => !prevState);
  };

  return (
    <div className='h-90vh overflow-y-scroll no-scrollbar pb-24'>
      <div
        id='Price'
        className='border-t border-gray block'
      >
        <SearchkitProvider searchkit={searchkit}>
          <>
            <div>
              <div className='cursor-pointer py-4 pb-11px'>
                <span className='text-lg text-nero capitalize block leading-5 font-medium select-none'>
                  Price
                </span>
              </div>
              <CheckboxFilter
                className={
                  'flex items-center cursor-pointerrounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
                }
                title='instantprice'
                id='instantprice'
                label='instant price'
                filter={TermQuery('isInstantPrice', true)}
                showCount={false}
                listComponent={renderCheckbox}
                mod={
                  'rounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
                }
              />
              {priceRange.max_price.value > 0 && (
                <RangeFilter
                  min={priceRange.min_price.value}
                  max={priceRange.max_price.value}
                  field='price.sortPrice'
                  id='sortPrice'
                  title={''}
                  showHistogram={false}
                  rangeComponent={renderPriceRange}
                />
              )}
            </div>
            <div
              id='discount-box'
              className='border-t border-gray'
            >
              <div
                className='flex items-center justify-between cursor-pointer py-4'
                onClick={toggleDiscountHandler}
              >
                <span className='text-lg text-nero capitalize block leading-5 font-medium select-none'>
                  Discount
                </span>
                <div className='pe-4'>
                  <Image
                    src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
                    width={12}
                    height={12}
                    priority={true}
                    alt='down arrow'
                  />
                </div>
              </div>
              <NumericRefinementListFilter
                mod='mr-1'
                id='discount'
                title=''
                field='discount'
                showCount={false}
                options={discountsList}
                listComponent={renderDiscountItem}
              />
            </div>
          </>
        </SearchkitProvider>
      </div>
    </div>
  );
};

export default DesktopSideBarFilters;
