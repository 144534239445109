import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

export const PreviousButton = ({ handleClick, label }) => (
  <div
    onClick={handleClick}
    className='me-8 text-sm leading-17px text-cinnabar h-9 border border-gray rounded-md
inline-flex justify-center items-center px-4 cursor-pointer hover:bg-platinum relative'
    data-qa='option'
    data-key='Previous'
  >
    <Image
      alt='Previous'
      className='w-full me-2'
      height={12}
      src={`${staticMediaStoreBaseURL}/icons/prev-arrow.svg`}
      width={12}
    />
    <Text
      {...{
        className: 'ms-2',
        content: label,
        HtmlTag: 'span'
      }}
    />
  </div>
);

export const NextButton = ({ handleClick, label }) => (
  <div
    className={`ms-8 text-sm leading-17px text-cinnabar h-9 border border-gray rounded-md
inline-flex justify-center items-center px-4 cursor-pointer hover:bg-platinum'`}
    data-key='Next'
    data-qa='option'
    onClick={handleClick}
  >
    <Text
      {...{
        className: 'me-2',
        content: label,
        HtmlTag: 'span'
      }}
    />
    <Image
      alt='Next'
      className='w-full ms-2'
      height={12}
      src={`${staticMediaStoreBaseURL}/icons/next-arrow.svg`}
      width={12}
    />
  </div>
);

export const OtherButton = ({ handleClick, label, active }) => (
  <div
    className={`${label === '1' ? 'ms-0' : 'ms-4'}
 w-full ${
  active ? 'text-white bg-cinnabar' : 'text-nero'
  } text-base hover:text-white leading-5
 h-7 min-w-6 max-w-6 w-full inline-flex justify-center items-center hover:bg-cinnabar focus:bg-cinnabar
 cursor-pointer rounded-sm`}
    data-key={label}
    data-qa='option'
    onClick={handleClick}
  >
    <Text
      {...{
        content: label,
        HtmlTag: 'span'
      }}
    />
  </div>
);
