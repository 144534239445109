import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';

import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const CategoryIcon = ({ category, index, categorySlug, parentSlug }) => {
  const defaultCategoryIconColour = `${staticMediaStoreBaseURL}/default-images/default_category_icon_color.svg`;
  const defaultCategoryIconPlain = `${staticMediaStoreBaseURL}/default-images/default_category_icon_plain.svg`;
  const colouredLogoUrl = category.coloredLogoUrl
    ? `${mediaStoreBaseURL}/${category.coloredLogoUrl}`
    : defaultCategoryIconColour;
  const logoUrl = category.logoUrl
    ? `${mediaStoreBaseURL}/${category.logoUrl}`
    : defaultCategoryIconPlain;

  const [imageSrc, setImageSrc] = useState(
    categorySlug === category.slug ? colouredLogoUrl : logoUrl
  );
  useEffect(() => {
    setImageSrc(categorySlug === category.slug ? colouredLogoUrl : logoUrl);
  }, [category]);

  return (
    <Link
      href={getPageURL({
        pageName: ROUTE_CONFIG.L1_CATEGORY.label,
        pathParams: {
          parentSlug,
          categorySlug: categorySlug !== category.slug ? category.slug : ''
        }
      })}
    >
      <div
        className='text-center bg-white cursor-pointer w-18.5 h-full mx-auto'
        key={index}
      >
        <div className='flex justify-center items-center relative bg-lightpink h-10 w-10 rounded-full mx-auto'>
          <Image
            alt={category.name}
            className='max-h-6'
            height={20}
            onError={() =>
              setImageSrc(
                categorySlug === category.slug
                  ? defaultCategoryIconColour
                  : defaultCategoryIconPlain
              )
            }
            src={imageSrc}
            width={20}
          />
        </div>
        <div className='w-full'>
          <p
            className={`whitespace-break w-full mt-1 text-10px  ${
              categorySlug === category.slug
                ? 'bg-clip-text text-transparent bg-brand-gradient'
                : 'text-dim-gray'
            } font-medium font-Montserrat leading-3`}
          >
            {category.name && category.name.length > 20
              ? `${category.name.slice(0, 20)}..`
              : category.name}{' '}
          </p>
        </div>
      </div>
    </Link>
  );
};

const ProductCategories = ({ categories, categorySlug, parentSlug }) => {
  useEffect(() => {
    const catEle = document.getElementById('categoryId');
    if (catEle) {
      // scroll l2 to left
      catEle.scrollLeft = 0;
    }
  }, [categorySlug]);

  return (
    <Fragment>
      <div
        className='relative px-5 md:pb-3 flex flex-row overflow-x-scroll no-scrollbar md:hidden'
        id='categoryId'
      >
        <div
          className={`flex flex-nowrap space-x-1 justify-between items-start ${
            categories.length > 4 ? 'mx-auto' : ''
          }`}
        >
          {categories &&
            categories.length &&
            categories.map((category, index) =>
              categorySlug === category.slug ? (
                <CategoryIcon
                  category={category}
                  key={category.id}
                  index={index}
                  categorySlug={categorySlug}
                  parentSlug={parentSlug}
                />
              ) : (
                ''
              )
            )}
          {categories &&
            categories.length &&
            categories.map((category, index) =>
              categorySlug !== category.slug ? (
                <CategoryIcon
                  category={category}
                  key={category.id}
                  index={index}
                  categorySlug={categorySlug}
                  parentSlug={parentSlug}
                />
              ) : (
                ''
              )
            )}
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCategories;
