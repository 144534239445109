import Image from 'next/image';
import { Fragment } from 'react';
import {
  CheckboxFilter,
  HitsStats,
  NumericRefinementListFilter,
  RangeFilter,
  RangeSlider,
  TermQuery
} from 'searchkit';

import { staticMediaStoreBaseURL } from '@/config/common';

const ProductFilterModal = ({
  openTab,
  priceRange,
  setAppliedFilters,
  setCloseModal,
  setOpenTab,
  updateCurrentUrl
}) => {
  const discountsList = [
    {
      title: 'Below 10%',
      from: 0,
      to: 10
    },
    {
      title: '10% and Above',
      from: 10
    },
    {
      title: '20% and Above',
      from: 20
    },
    {
      title: '30% and Above',
      from: 30
    },
    {
      title: '40% and Above',
      from: 40
    }
  ];

  const customHitStats = (props) => {
    const { hitsCount } = props;
    const handleApplyFilters = () => {
      updateCurrentUrl();
      setOpenTab(1);
      setCloseModal(false);
    };

    return (
      <div className='fixed bottom-0 left-0 z-120 w-full h-18'>
        <div className='flex justify-between items-center px-5 py-4 border-t border-gray bg-white box-border'>
          <div className='w-1/2'>
            <span className='text-xs font-medium leading-4 text-nero mb-1 underline block'>
              {' '}
              {hitsCount}{' '}
            </span>
            <p className='text-xs font-medium leading-4 text-dim-gray'>
              {' '}
              Products found{' '}
            </p>
          </div>

          <div
            className='w-1/2 text-right'
            onClick={handleApplyFilters}
          >
            <button
              type='submit'
              className='h-10 max-w-35 w-full rounded-md font-Montserrat text-base leading-5 text-white
            font-medium bg-brand-gradient'
            >
              {' '}
              Apply{' '}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderCheckbox = (props) => (
    <ul className='instantprice'>
      <li className='mx-4 h-11 flex justify-start items-center border-b border-gray'>
        <label>
          <div className='flex items-center'>
            <input
              type='checkbox'
              name='Instant Price Products'
              className='rounded-sm box-border border-gray border-1 text-nero focus:border-nero focus:ring-nero w-4 h-4'
              onChange={() => {
                props.toggleItem();
                setAppliedFilters((prevState) => [
                  ...prevState.filter((data) => data !== 'isInstantPrice')
                ]);
              }}
              checked={props.selectedItems.length}
            />
            <p className='text-nero font-normal text-xs leading-5 capitalize ml-3'>
              {' '}
              Instant Price Products Only{' '}
            </p>
          </div>
        </label>
      </li>
    </ul>
  );

  const renderPrice = (props) => (
    <div className='price mx-5 block'>
      <div className='block mt-5'>
        <p className='text-nero font-normal text-xs leading-5 capitalize'>
          {' '}
          Selected price range{' '}
        </p>
        <p className='text-nero text-sm leading-17px font-semibold mt-2 mb-6'>
          AED {props.children.props.minValue} - AED{' '}
          {props.children.props.maxValue}
        </p>
        <div className='max-w-44 mr-auto relative'>{props.children}</div>
      </div>
    </div>
  );

  const renderDiscountItem = (props) =>
    props &&
    props.items.map((item) => (
      <li
        key={item.key}
        className='mx-4 h-11 flex justify-start items-center border-b border-gray'
        onClick={(e) => {
          e.preventDefault();
          props.toggleItem();
          const setItem = props.selectedItems[0] !== item.key ? item.key : '';
          props.setItems(setItem);
        }}
      >
        <label>
          <div className='flex items-center'>
            <Image
              src={`${staticMediaStoreBaseURL}${
                props.selectedItems[0] === item.key
                  ? '/icons/checkbox-orange.svg'
                  : '/icons/checkbox-grey.svg'
              }`}
              height={16}
              width={16}
              alt={item.key}
            />
            <p className='ms-2 basic:ms-4.5 text-nero font-normal text-xs basic:text-sm leading-5 capitalize'>
              {' '}
              {item.key}{' '}
            </p>
            <input
              type='checkbox'
              name='10% and above'
              value='10% and above'
              className='hidden'
            />
          </div>
        </label>
      </li>
    ));
  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='w-1/3 bg-lightpink h-customscreen min-w-30'>
          <ul
            className='bg-lightpink'
            role='tablist'
          >
            <li
              className={`border-b border-white h-11 flex justify-start items-center ${
                openTab === 1 ? 'bg-white' : 'bg-lightpink'
              }`}
            >
              <label
                className={`px-4 text-xs leading-4 text-nero w-full ${
                  openTab === 1 ? 'font-semibold' : 'font-normal'
                }`}
                onClick={() => {
                  setOpenTab(1);
                }}
                data-toggle='tab'
                href='#price'
                role='tablist'
              >
                Price
              </label>
            </li>
            <li
              className={`border-white h-11 flex justify-start items-center ${
                openTab === 2 ? 'bg-white' : 'bg-lightpink'
              }`}
            >
              <label
                className={`px-4 text-sm leading-4 text-nero w-full ${
                  openTab === 2 ? 'font-semibold' : 'font-medium'
                }`}
                onClick={() => {
                  setOpenTab(2);
                }}
                data-toggle='tab'
                href='#price'
                role='tablist'
              >
                Discount
              </label>
            </li>
          </ul>
        </div>

        <div className='w-2/3'>
          <div className='tab-content tab-space font-Montserrat'>
            <div
              className={openTab === 1 ? 'block' : 'hidden'}
              id='price'
            >
              <CheckboxFilter
                title='isInstantPrice'
                className={
                  'flex items-center cursor-pointerrounded-sm box-border border-gray border-1 text-nero focus:border-nero focus:ring-nero w-4 h-4'
                }
                id='isInstantPrice'
                filter={TermQuery('isInstantPrice', true)}
                showCount={false}
                listComponent={renderCheckbox}
                mod={
                  'rounded-sm box-border border-gray border-1 text-nero focus:border-nero focus:ring-nero w-4 h-4'
                }
              />
              {priceRange?.max_price?.value > 0 && (
                <RangeFilter
                  field='price.sortPrice'
                  id='sortPrice'
                  title={' '}
                  min={priceRange?.min_price?.value}
                  max={priceRange?.max_price?.value}
                  showHistogram={false}
                  containerComponent={renderPrice}
                  rangeComponent={(props) => (
                    <RangeSlider
                      mod={'custom-slider'}
                      {...props}
                    />
                  )}
                />
              )}
            </div>

            <div
              className={openTab === 2 ? 'block' : 'hidden'}
              id='discount'
            >
              <ul className='discount'>
                {
                  <NumericRefinementListFilter
                    id='discount'
                    title=''
                    field='discount'
                    showCount={false}
                    options={discountsList}
                    listComponent={renderDiscountItem}
                  />
                }
              </ul>
            </div>

            <div
              className={openTab === 4 ? 'block' : 'hidden'}
              id='eventrecommended'
            >
              <ul className='eventrecommended'>
                <li className='mx-4 h-11 flex justify-start items-center border-b border-gray'>
                  <label>
                    <div className='flex items-center'>
                      <Image
                        src={`${staticMediaStoreBaseURL}/icons/checkbox-grey.svg`}
                        height={16}
                        width={16}
                        alt='checkbox Grey'
                      />
                      <p className='ms-2 basic:ms-4.5 text-nero font-normal text-xs basic:text-sm leading-5 capitalize'>
                        {' '}
                        Vila{' '}
                      </p>
                      <input
                        type='checkbox'
                        name='Vila'
                        value='Vila'
                        className='hidden'
                      />
                    </div>
                  </label>
                </li>

                <li className='mx-4 h-11 flex justify-start items-center border-b border-gray'>
                  <label>
                    <div className='flex items-center'>
                      <Image
                        src={`${staticMediaStoreBaseURL}/icons/checkbox-grey.svg`}
                        height={16}
                        width={16}
                        alt='checkbox Grey'
                      />
                      <p className='ms-2 basic:ms-4.5 text-nero font-normal text-xs basic:text-sm leading-5 capitalize'>
                        {' '}
                        Backyard{' '}
                      </p>
                      <input
                        type='checkbox'
                        name='Backyard'
                        value='Backyard'
                        className='hidden'
                      />
                    </div>
                  </label>
                </li>

                <li className='mx-4 h-11 flex justify-start items-center border-b border-gray'>
                  <label>
                    <div className='flex items-center'>
                      <Image
                        src={`${staticMediaStoreBaseURL}/icons/checkbox-grey.svg`}
                        height={16}
                        width={16}
                        alt='checkbox Grey'
                      />
                      <p className='ms-2 basic:ms-4.5 text-nero font-normal text-xs basic:text-sm leading-5 capitalize'>
                        {' '}
                        Garden{' '}
                      </p>
                      <input
                        type='checkbox'
                        name='Garden'
                        value='Garden'
                        className='hidden'
                      />
                    </div>
                  </label>
                </li>

                <li className='mx-4 h-11 flex justify-start items-center border-b border-gray'>
                  <label>
                    <div className='flex items-center'>
                      <Image
                        src={`${staticMediaStoreBaseURL}/icons/checkbox-grey.svg`}
                        height={16}
                        width={16}
                        alt='checkbox Grey'
                      />
                      <p className='ms-2 basic:ms-4.5 text-nero font-normal text-xs basic:text-sm leading-5 capitalize'>
                        {' '}
                        Public Parks{' '}
                      </p>
                      <input
                        type='checkbox'
                        name='Public Parks'
                        value='Public Parks'
                        className='hidden'
                      />
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <HitsStats component={customHitStats} />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductFilterModal;
