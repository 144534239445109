import Link from 'next/link';
import { Fragment, useEffect } from 'react';

import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const ProductSubCategory = ({
  categorySlug,
  parentSlug,
  subCategories,
  subCategorySlug
}) => {
  const renderSubCategoryLabel = (subCategory, index) => {
    if (subCategory === undefined) {
      return false;
    }
    const redirectUrl =
      subCategory.name === 'All'
        ? getPageURL({
          pageName: ROUTE_CONFIG.L1_CATEGORY.label,
          pathParams: {
            parentSlug,
            categorySlug:
                categorySlug !== categorySlug.slug ? categorySlug : ''
          }
        })
        : getPageURL({
          pageName: ROUTE_CONFIG.L2_CATEGORY.label,
          pathParams: {
            parentSlug,
            categorySlug,
            subCategorySlug:
                subCategorySlug !== subCategory.slug ? subCategory.slug : ''
          }
        });

    return (
      <div
        className={`${
          subCategorySlug === subCategory.slug ||
          (!subCategorySlug && subCategory.name === 'All')
            ? 'text-white bg-brand-gradient shadow-search'
            : 'text-dim-gray bg-white shadow-search'
        } text-xs md:text-sm font-medium p-2 md:p-3 rounded-md cursor-pointer md:h-10`}
        value={subCategory.slug}
        name={subCategory.name}
        key={index}
        id={index}
      >
        <Link href={redirectUrl}>
          <a>{subCategory.name}</a>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    const subCatEle = document.getElementById('subCategoryId');
    if (subCatEle) {
      // scroll l2 to left
      subCatEle.scrollLeft = 0;
    }
  }, [subCategorySlug]);

  return (
    <div
      className={
        'flex flex-row overflow-x-scroll no-scrollbar whitespace-nowrap space-x-2 md:pb-8 px-5 pt-2.5 pb-1'
      }
      id='subCategoryId'
    >
      {subCategories && subCategories.length ? (
        <Fragment>
          {renderSubCategoryLabel(
            {
              slug: '',
              name: 'All'
            },
            '01'
          )}
          {renderSubCategoryLabel(
            subCategories.find(
              (subCategory) => subCategorySlug === subCategory.slug
            )
          )}
          {subCategories.map(
            (subCategory, index) =>
              subCategorySlug !== subCategory.slug &&
              renderSubCategoryLabel(subCategory, index)
          )}
        </Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProductSubCategory;
