import { Text } from '@/components/atomic/nuclei';

const ResetFilterItem = ({ resetFilters }) => (
  <div
    className='ms-4'
    onClick={resetFilters}
  >
    <label className='h-9 flex justify-center items-center border border-platinum cursor-pointer px-4 rounded-md min-w-23 hover:bg-ghost-white'>
      <Text
        {...{
          content: 'Clear All',
          HtmlTag: 'span',
          className:
            'text-coral font-medium text-sm leading-17px capitalize select-none'
        }}
      />
    </label>
  </div>
);

const RenderResetFilter = ({ hasFilters, resetFilters }) =>
  hasFilters && <ResetFilterItem {...{ resetFilters }} />;

export default RenderResetFilter;
